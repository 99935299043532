export const initialState= {
	firstName: '',
	lastName: '',
	email: '',
	address: '',
	city: '',
	username: '',
	userid: null,
	job: '',
	location: '',
	aboutUs: '',
	confirmed: false,
	pool: '',
	cc: '',
	userExists: null,
	confirmationFailed: false,
	authFailed: false,
	validCognitoPassword: null
};

const registrationReducer = (state = initialState, action) => {
	switch(action.type) {
		case 'UPDATE_CLEANED_REGISTRATION_STATE':
			return {...state, ...action.data};
		case 'USER_REGISTERED_BACKEND': {
			const urb = Object.assign({}, state);
			urb.userid = action.data.userId;
			urb.confirmed = urb.userid ? true : false;
			if(urb.confirmed){
				localStorage.setItem('ap_i', ''+urb.userid);
			}
			return urb;
		}
		case 'USER_REGISTERED_COGNITO': {
			const urc = Object.assign({}, state);
			urc.authFailed = false;
			if(action.data.user && action.data.user.pool){
				urc.pool = action.data.user.pool.userPoolId
			}
			return urc;
		}
		case 'COGNITO_USER_EXISTS': {
			const cue = Object.assign({}, state);
			cue.userExists = action.data;
			return cue;
		}
		case 'COGNITO_CONFIRMATION_FAILED': {
			const ccf = Object.assign({}, state);
			ccf.confirmationFailed = true;
			return ccf;
		}
		case 'COGNITO_CONFIRMATION_SUCCESS': {
			const ccf = Object.assign({}, state);
			ccf.confirmationFailed = false;
			return ccf;
		}
		case 'COGNITO_USER_UNCONFIRMED': {
			const cuu = Object.assign({}, state)
			cuu.confirmed = false;
			cuu.authFailed = false;
			if(action.data.user && action.data.user.pool){
				cuu.pool = null;
			}
			return cuu;
		}
		case 'COGNITO_USER_CONFIRMED': {
			const cuc = Object.assign({}, state)
			cuc.confirmed = true;
			cuc.authFailed = false;
			if(action.data.user && action.data.user.pool){
				cuc.pool = action.data.user.pool.userPoolId;
			}
			return cuc;
		}
		case 'COGNITO_USER_NOT_AUTHORIZED': {
			return {...state, authFailed: true};
		}
		case 'COGNITO_VALID_PASSWORD': {
			return {...state, validCognitoPassword: action.data};
		}
		default:
			return state;
	}
};

export default registrationReducer;

