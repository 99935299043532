import { put, takeEvery } from 'redux-saga/effects';
import { Auth } from 'aws-amplify';
import apiCall from '../../util/axiosAPIRequest/axiosAPIRequest';

export function* GetQuestions(){
    yield put({type: 'TOGGLE_LOADING_SPINNER', data: { showLoadingSpinner: true } });

    let serverData;
    let statusCode;
    let cognitoUser;

    yield Auth.currentAuthenticatedUser()
        .then(data => cognitoUser = data )
        .catch(err => console.log(err));

    yield put({type: 'COGNITO_USER', data: cognitoUser});

    yield apiCall('screening/netapi/v1/assessmentinstance/getcurrent', 'GET')
        .then((res) => {
            serverData = res;
            statusCode = res.status;
        })
        .catch((err) => {
            if (err.response) {
                statusCode = err.response.status;
              }
        });

    if(statusCode === 404 && !serverData){
        yield put({type: 'SET_GO_REGISTER', data: true})
    } else if(serverData && serverData.data && serverData.data.endTime){
        if(serverData.data.client){
            yield put({type: 'SET_CONFIG', theme: serverData.data.client.clientName});
            yield put({type: 'SET_THEME', theme: serverData.data.client.clientName});
        }
        yield put({type: 'COMPLETED_SCREENING'})
    }else if(serverData && serverData.data){
        if(serverData.data.client){
            yield put({type: 'SET_CONFIG', theme: serverData.data.client.clientName});
            yield put({type: 'SET_THEME', theme: serverData.data.client.clientName});
        }
        yield put({ type: 'ADD_SECTIONS' , data: serverData.data.sectionInstances });
    }
    if(serverData && serverData.data && serverData.data.configuration){
        yield put({type: 'SET_DATA_CONFIG', data: serverData.data.configuration});
    }
    if(serverData && serverData.data && serverData.data.userID){
        localStorage.setItem('ap_i', ''+serverData.data.userID);
    }

    yield put({type: 'TOGGLE_LOADING_SPINNER', data: { showLoadingSpinner: false } });
}

export function* initSection(action){
    yield put({type: 'TOGGLE_LOADING_SPINNER', data: { showLoadingSpinner: true } });
    let apiRes;
    yield apiCall('screening/netapi/v1/sectionInstance', 'PUT', action.data)
    .then((res) => {
        apiRes = res.data;
    })
    .catch((err) => {
        console.log(err);
    });
    yield put({type: 'UPDATE_SAVED_SECTION', data: apiRes});
    yield put({type: 'TOGGLE_LOADING_SPINNER', data: { showLoadingSpinner: false } });
}

export function* watchInitSection(){
    yield takeEvery('INIT_SECTION', initSection);
}

export function* saveSection(dat){
    yield put({type: 'TOGGLE_LOADING_SPINNER', data: { showLoadingSpinner: true } });
    let apiRes;
    yield apiCall('screening/netapi/v1/sectionInstance', 'PUT', dat.data.selectedSection)
    .then((res) => {
        apiRes = res.data;
    })
    .catch((err) => {
        console.log(err);
    });

    //signed out if click save&exit button
    if(dat.data.signOut === true){
        yield Auth.signOut();
        window.location.href=process.env.REACT_APP_env_sso_redirect_url;
    }else{
        yield put({type: 'UPDATE_SAVED_SECTION', data: apiRes});

        if(dat.data.nextSection){
            yield put({type: 'SET_SECTION', data: dat.data.nextSection});
        }
    
        yield put({type: 'TOGGLE_LOADING_SPINNER', data: { showLoadingSpinner: false } });
    }
}

export function* watchSaveSection(){
    yield takeEvery('SAVE_SECTION', saveSection);
}

export function* logOut(){
    yield Auth.signOut();
    window.location.href=process.env.REACT_APP_env_sso_redirect_url;
}

export function* watchLogout(){
    yield takeEvery('LOG_OUT', logOut);
}

export function* watchInitAssessment(){
    yield takeEvery('INIT_ASSESSMENT', GetQuestions);
}

export function* CompleteAssessment(action){ 
    yield put({type: 'TOGGLE_LOADING_SPINNER', data: { showLoadingSpinner: true } });
    
    const assessmentId = action.data.assessmentInstanceID;

    let apiRes;

    yield apiCall('screening/netapi/v1/sectionInstance', 'PUT', action.data)
    .then((res) => {
        apiRes = res.data;
    })
    .catch((err) => {
        console.log(err);
    });
    yield put({type: 'UPDATE_SAVED_SECTION', data: apiRes});

    let sRes;

    yield apiCall('screening/netapi/v1/AssessmentInstance/Complete/' + assessmentId, 'PUT')
        .then((res) => {
            sRes = res;
        });

    if(sRes.data === 'Success'){
        yield put({type: 'SCREENING_SUBMITTED'});
        yield put({type: 'COMPLETED_SCREENING'});
    }else{
        yield put({type: 'TOGGLE_REQUIRED_MODAL'});
    }
    yield put({type: 'TOGGLE_LOADING_SPINNER', data: { showLoadingSpinner: false } });
}

export function* watchCompleteAssessment(){
    yield takeEvery('COMPLETE_ASSESSMENT', CompleteAssessment);
}



