import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SingleFormComponent from '../../components/RegistrationComponent/SingleFormComponent';
import configs from '../../configuration/configuration.json';
import MobileSingleFormComponent from '../../components/RegistrationComponent/MobileSingleFormComponent';
import { getClientBaseUrl } from '../../util/helper/Helper';

const stateList = configs['default'].states;

const RegistrationSingleFormContainer = (props) => {
  let formProps = { ...props };
  let redirectTo = getClientBaseUrl()

  const dispatch = useDispatch();

  //** Recaptcha **/
  const handleLoaded = _ => {
    window.grecaptcha.ready(_ => {
      window.grecaptcha
        .execute("6Lfvaz4aAAAAAKj9Lvx8KB-3AqEGt3TrbTVc-x3C", { action: "registration" })
        .then(token => {
        });
    })
  }

  useEffect(() => {
    if (process.env.REACT_APP_env_cookie_domain === 'catalyte.io') {
      const script = document.createElement("script");
      script.src = "https://www.google.com/recaptcha/api.js?render=6Lfvaz4aAAAAAKj9Lvx8KB-3AqEGt3TrbTVc-x3C";
      script.id = "g-recaptcha-script";
      script.addEventListener("load", handleLoaded);
      document.body.appendChild(script);
    }
  }, [])

  //** vvv Google Sign in vvv **/
  const fedSignIn = () => {
    console.log('signing in with Google')
    localStorage.setItem('ssoRegState', JSON.stringify({ ...regState, consent, ts: Date.now() }));
    let redirectTo = getClientBaseUrl()

    dispatch({ type: 'TOGGLE_LOADING_SPINNER', data: { showLoadingSpinner: true } });

    window.location.replace(`${process.env.REACT_APP_env_sso_redirect_url}?federatedSignIn=Google&redirectTo=${redirectTo}registration`);

    dispatch({ type: 'TOGGLE_LOADING_SPINNER', data: { showLoadingSpinner: false } });
  };
  //** ^^^ Google Sign in ^^^ **/

  //** Field Values **//
  const [firstName, setFname] = useState(props.firstName);
  const [lastName, setLname] = useState(props.lastName);
  const [password, setPassword] = React.useState(props.password);
  const [confirmedPassword, setConfirmedPassword] = React.useState(props.confirmedPassword);
  const [consent, setConsent] = React.useState(props.consent);
  const [signup, setSignup] = React.useState(props.signup);
  const [email, setEmail] = useState(props.email);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowPWEyeButton, setIsShowPWEyeButton] = useState(null);
  const [isShowCPWEyeButton, setIsShowCPWEyeButton] = useState(null);
  const [global, setGlobal] = useState(props.globalState);
  const phone = '111-111-1111';
  const aboutUs = '';
  const address = '1111 Single Form Street';
  const apt = '';
  const city = 'Single Form City';
  const state = 'MD';
  const zipcode = '21043';

  //** Field validation **//
  const [isValidFname, setValidFname] = useState(props.firstName ? validateFirstName(props.firstName) : null);
  const [isValidLname, setValidLname] = useState(props.lastName ? validateLastName(props.lastName) : null);
  const [isValidPassword, setValidPassword] = React.useState(null);
  const [isValidConfirmedPassword, setValidConfirmedPassword] = React.useState(null);
  const [isValidConsent, setValidConsent] = React.useState(consent);
  const [isSubmitAction, setIsSubmitAction] = useState(false);
  const [isValidEmail, setValidEmail] = useState(null);
  const [isValid, setIsValid] = useState(false);
  const [isFirstPasswordAttempt, setIsFirstPasswordAttempt] = useState(true);
  const regState = useSelector((state) => state.registration);

  //** Form Props **// aboutUs, address, phone, apt, city, state, zipcode
  formProps.firstName = firstName;
  formProps.lastName = lastName;
  formProps.aboutUs = aboutUs;
  formProps.address = address;
  formProps.phone = phone;
  formProps.apt = apt;
  formProps.city = city;
  formProps.state = state
  formProps.zipcode = zipcode;
  formProps.isValidFname = isValidFname;
  formProps.isValidLname = isValidLname;
  formProps.password = password;
  formProps.confirmedPassword = confirmedPassword;
  formProps.consent = consent;
  formProps.signup = signup;
  formProps.email = email;
  formProps.isValidPassword = isValidPassword;
  formProps.isValidConfirmedPassword = isValidConfirmedPassword;
  formProps.isValidConsent = isValidConsent;
  formProps.isSubmitAction = isSubmitAction;
  formProps.isValidEmail = isValidEmail;
  formProps.isValid = isValid;
  formProps.isFirstPasswordAttempt = isFirstPasswordAttempt;
  formProps.fedSignIn = fedSignIn;
  formProps.passwordType = isShowPassword ? 'text' : 'password';
  formProps.confirmPasswordType = isShowPassword ? 'text' : 'password';
  formProps.isShowPWEyeButton = isShowPWEyeButton;
  formProps.isShowCPWEyeButton = isShowCPWEyeButton;

  /** Helpers */
  formProps.getPasswordHelperText = (v, validCognitoPassword) => {
    let helperText = '';
    if(!v) {
        if(validCognitoPassword !== null && !validCognitoPassword) {
            helperText = validCognitoPassword ? helperText : 'Password is too simple. Please choose another password.';
            return helperText;
        }
        return 'Password is required.'; 
    }
    const invalidLengthMsg = 'Password must be at least 8 characters.\n';
    const invalidNums = 'Password must contain at least one number.\n';
    const invalidSpecial = 'Password must contain at least one special character.\n';
    const invalidCase = 'Password must contain upper and lower case characters.';

    const validLength = v.length > 7;
    const hasNums = /\d/.test(v);
    const hasSpecial = !/^[A-Za-z0-9]*$/.test(v);
    const hasMixedCase = /[a-z]/.test(v) && /[A-Z]/.test(v);

    helperText = validLength ? helperText : helperText + invalidLengthMsg;
    helperText = hasNums ? helperText : helperText + invalidNums;
    helperText = hasSpecial ? helperText : helperText + invalidSpecial;
    helperText = hasMixedCase ? helperText : helperText + invalidCase;

    return helperText;
}

  //** Field Validators **//
  const validateFirstName = formProps.validateFirstName = (val) => {
    let validFirstName = val.length >= 1 && val.length <= 255 && val.trim() !== '';
    return validFirstName;
  };

  const validateLastName = formProps.validateLastName = (val) => {
    let validLastName = val.length >= 1 && val.length <= 255 && val.trim() !== '';
    return validLastName;
  };

  const validatePassword = formProps.validatePassword = (v) => {
    if (!v) {
      return false;
    }

    const validLength = v.length > 7;
    const hasNums = /\d/.test(v);
    const hasSpecial = !/^[A-Za-z0-9]*$/.test(v);
    const hasMixedCase = /[a-z]/.test(v) && /[A-Z]/.test(v);

    let validPassword = validLength && hasNums && hasSpecial && hasMixedCase;
    return validPassword;
  }

  const validateConsent = formProps.validateConsent = (v) => {
    if (!v || v !== 'agree') {
      return false;
    }
    return true;
  }

  const validateEmail = formProps.validateEmail = (email) => {
    let validEmail = !!email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    return validEmail;
  };

  const getIsValid = props.getIsValid = () => {
    const fnameValid = validateFirstName(firstName);
    const lnameValid = validateLastName(lastName);
    // const extIsValid = validateExtNumber(ext);

    return fnameValid && lnameValid;
  }

  //** Change Handlers **// 
  const fnameId = 'fname-input';
  const lnameId = 'lname-input';

  formProps.onFnameChange = (v) => {
    if (formProps.globalState.cognitoUser && formProps.globalState.cognitoUser.given_name) {
      return
    }
    setFname(v);
    if (document.activeElement.id === fnameId || isValidFname !== null) {
      setValidFname(validateFirstName(v));
    }
  };

  formProps.onLnameChange = (v) => {
    if (formProps.globalState.cognitoUser && formProps.globalState.cognitoUser.family_name) {
      return
    }
    setLname(v);
    if (document.activeElement.id !== lnameId || isValidLname !== null) {
      setValidLname(validateLastName(v));
    }
  };

  //** Secondary Form Field change handlers **/
  const emailId = 'email-input'
  const pwdId = 'pwd-input'
  const cnfrmId = 'cnfrm-input'

  formProps.onPasswordChange = (v, f) => {
    if (f) setIsShowPWEyeButton(true);
    setPassword(v);
    if (!isFirstPasswordAttempt) {
      if (isValidConfirmedPassword !== null) {
        setValidConfirmedPassword(v === confirmedPassword);
      }
    }

    if (document.activeElement.id !== pwdId || isValidPassword !== null) {
      setValidPassword(validatePassword(v));
    }
  }

  formProps.onConfirmedPasswordChange = (v, f) => {
    if (f) setIsShowCPWEyeButton(true);
    setConfirmedPassword(v);
    setValidConfirmedPassword(v === password);

    if (document.activeElement.id !== cnfrmId || isValidConfirmedPassword !== null) {
      setValidConfirmedPassword(validatePassword(v));
    }
  }

  formProps.onConsentChange = (v) => {
    let newV = consent === 'agree' ? 'no' : 'agree';
    setValidConsent(validateConsent(newV));
    setConsent(newV);
  }

  formProps.onSignupChange = (v) => {
    console.log('Signup changed to ' + v);
    let newV = signup === 'useEmail' ? 'useSSO' : 'useEmail';
    setSignup(newV);
  }

  formProps.onEmailChange = (v) => {
    if (formProps.globalState.cognitoUser) {
      return
    }

    setEmail(v);

    if (document.activeElement.id === emailId || isValidEmail !== null) {
      setValidEmail(validateEmail(v));
    }
  }

  //** Key Handlers **//
  formProps.handleOnKeyDown = (e) => {
    if (isValid && (e.key === "Enter")) {
      formProps.handleSubmit(e);
    }
  }

  //** Click Handlers **//
  formProps.handleSubmit = (e) => {

    e.preventDefault();
    setIsSubmitAction(true);

    if (getIsValid(true)) {
      console.log('IS VALID');
      props.onClickSubmit({ firstName, lastName, aboutUs, address, phone, apt, city, state, zipcode, consent, password, email });
    } else {
      console.log('NOT VALID');
    }
  }

  formProps.handleEyeClick = (e) => {
    setIsShowPassword(!isShowPassword);
  }

  formProps.onPWFocus = (id) => {
    if (id === 'pwd-input' || id === 'pwd-inputeye') {
      setIsShowPWEyeButton(true);
      setIsShowCPWEyeButton(false);
    }
    else if (!id) {
      setIsShowPWEyeButton(false);
      setIsShowCPWEyeButton(false)
    }
  }

  formProps.onCPWFocus = (id) => {
    if (id === 'cnfrm-input' || id === 'cnfrm-inputeye') {
      setIsShowCPWEyeButton(true);
      setIsShowPWEyeButton(false);
    }
    else if (!id) {
      setIsShowPWEyeButton(false);
      setIsShowCPWEyeButton(false);
    }
  }

  //** Blur Handlers **//
  formProps.onLnameBlur = (v) => {
    setValidLname(validateLastName(v));
  }

  formProps.onFnameBlur = (v) => {
    setValidFname(validateFirstName(v));
  }

  formProps.onPasswordBlur = (v) => {
    setIsFirstPasswordAttempt(false);
    setValidPassword(validatePassword(v));
  }

  formProps.onConfirmedPasswordBlur = (v) => {
    setValidConfirmedPassword(v === password);
  }

  formProps.onEmailBlur = (v) => {
    setValidEmail(validateEmail(v));
    dispatch({ 'type': 'COGNITO_USER_EXISTS', data: null });
    props.debouncedEmailCheck(email);
  }

  //** Helpers **//
  useEffect(() => {
    setFname(props.firstName);
    if (props.firstName) {
      setValidFname(validateFirstName(props.firstName));
    }
  }, [props.firstName])

  useEffect(() => {
    setLname(props.lastName);
    if (props.lastName) {
      setValidLname(validateLastName(props.lastName));
    }
  }, [props.lastName])

  useEffect(() => {
    scrollToTop();
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0
    });
  }

  //** Secondary Form Helpers **//
  formProps.getConsentHelperText = (v) => {
    if (!v || v != 'agree') {
      return 'Please read and agree to the terms and conditions.'

    } else {
      return '';
    }
  }

  formProps.getEmailHelper = () => {
    if (props.userExists && validateEmail(email)) {
      return <p>Email already in use. Please <a href={process.env.REACT_APP_env_sso_redirect_url + '?redirectTo=' + redirectTo}>log in</a> or try another email.</p>;
    }
    if (!isValidEmail) {
      return 'Please enter a valid email address';
    }
    return '';
  }

  useEffect(() => {
    setIsValid(getIsValid());
  }, [firstName, lastName, password, confirmedPassword, consent, email, props.userExists, getIsValid])

  useEffect(() => {
    if (regState.validCognitoPassword !== null && !regState.validCognitoPassword) {
      setValidPassword(regState.validCognitoPassword);
      setValidConfirmedPassword(null);
      setPassword(props.password);
      setConfirmedPassword(props.confirmedPassword);
      setConsent(null);
      window.scrollTo(0, 0);
    }
  }, [regState.validCognitoPassword]);

  useEffect(() => {
    setGlobal(props.globalState);
  }, [props.globalState])

  useEffect(() => {
    setEmail(props.email);

    if (document.activeElement.id === emailId || isValidEmail !== null) {
      setValidEmail(validateEmail(props.email));
    }

  }, [props.email])

  useEffect(() => {
    if (props.userExists) {
      setValidEmail(false);
    }
  }, [props.userExists])


  if (props.isSmallScreen) {
    return (
      <MobileSingleFormComponent {...formProps}></MobileSingleFormComponent>
    )
  } else {
    return (
      <SingleFormComponent {...formProps}></SingleFormComponent>
    )
  }
}

export default RegistrationSingleFormContainer;
