import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import SecondaryButtonComponent from '../SecondaryButtonComponent/SecondaryButtonComponent';
import { ThemeContext } from 'styled-components';
import { useDispatch } from 'react-redux';
import { up } from 'styled-breakpoints';
import { getClientBaseUrl } from '../../util/helper/Helper';

const StyledRoot = styled.div`
    display: flex;
    justify-content: center;
    height: 4em;
    width: 100%;
    box-shadow: 1px 8px 35px ${props => props.theme.formShadow};
    background-color: ${props => props.theme.mainHeader};
`;

const StyledLogo = styled.img`
    width: 10.7em;
`;

const VerticalCenteringDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: ${props => props.width ? props.width : 'unset'};
`;

const StyledLoginLText = styled.div`
    font-size: 1em;
    font-weight: 400;
    color: white;
    padding-right: .7em;
`;

const StyledContainer = styled.div`
    display: flex;
    ${up('md')} {
        width:90%;
        justify-content: space-between;
    }
`;

const Row = styled.div`
    display: flex;
`;

const StyledLoginLink = styled.a`
    font-size: 1em;
    color: white;
    text-decoration: none;
`;

const Username = styled.div`
    color: white;
    font-weight: 500;
    padding-right: 1em;
`;

const DesktopHeaderComponent = (props) => {
    const themeContext = useContext(ThemeContext);
    const dispatch = useDispatch();

    const [username, setUsername] = useState(props.username);

    let redirectTo = getClientBaseUrl()

    useEffect(() => {
        setUsername(props.username);
    }, [props.username])

    return (
        <StyledRoot>
            <StyledContainer>
                <VerticalCenteringDiv>
                    <StyledLogo src={props.logoImageUrl} />
                </VerticalCenteringDiv>
                {props.currentSection &&
                    <VerticalCenteringDiv width='56%'>
                    </VerticalCenteringDiv>
                }
                {
                    props.showLogin &&
                    <VerticalCenteringDiv>
                        <Row>
                            <VerticalCenteringDiv>
                                <StyledLoginLText>
                                    Already registered?
                                </StyledLoginLText>
                            </VerticalCenteringDiv>
                            <VerticalCenteringDiv>
                                <StyledLoginLink href={process.env.REACT_APP_env_sso_redirect_url + '?redirectTo=' + redirectTo}>
                                    <SecondaryButtonComponent color={themeContext.headerText} borderColor={themeContext.headerButtonBorderColor} backgroundColor={themeContext.headerButtonBgColor} onClick={() => { }} text={'Log In'}></SecondaryButtonComponent>
                                </StyledLoginLink>
                            </VerticalCenteringDiv>
                        </Row>
                    </VerticalCenteringDiv>
                }
                {
                    props.showLogout &&
                    <VerticalCenteringDiv>
                        <Row>
                            <VerticalCenteringDiv>
                                <Username>{username}</Username>
                            </VerticalCenteringDiv>
                            <VerticalCenteringDiv>
                                <StyledLoginLink onClick={() => { dispatch({ type: 'LOG_OUT' }) }}>
                                    <SecondaryButtonComponent size={'small'} color={themeContext.headerButtonTextColor} borderColor={themeContext.headerButtonBorderColor} backgroundColor={themeContext.headerButtonBgColor} onClick={() => { }} text={'Log Out'}></SecondaryButtonComponent>
                                </StyledLoginLink>
                            </VerticalCenteringDiv>
                        </Row>
                    </VerticalCenteringDiv>
                }
            </StyledContainer>
        </StyledRoot>
    )
}

export default DesktopHeaderComponent;