import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import HomeComponent from '../HomeComponent/HomeComponent';
import { Redirect } from 'react-router-dom';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSelector, useDispatch } from 'react-redux';
import { isIE, isOpera } from 'react-device-detect';


const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  color: #8B8B8B;
`;

const WrapperComponent = () =>{ 
  const dispatch = useDispatch();
  const sections = useSelector((state) => state.sections);
  const global = useSelector((state) => state.global);

  useEffect(() => {
    dispatch({ type: 'SET_GO_HOME', data: false });
  },[])

  useEffect(() => {
    if (sections && sections.length === 0) {
      dispatch({ type: 'INIT_ASSESSMENT' });
    }
  }, [sections])

  const flags = useFlags();

  // const ldClient = useLDClient();

  // useEffect(() => {
  //   if(global.cognitoUser){
  //     let user = {
  //       key: global.cognitoUser.username,
  //       name: `${global.cognitoUser.attributes.given_name} ${global.cognitoUser.attributes.family_name}`,
  //       email: global.cognitoUser.attributes.email
  //     }

  //     ldClient.identify(user, undefined, () => {
  //       console.log("New user's flags available");
  //     });
  //   }
  // }, [global.cognitoUser])

  useEffect(() => {
    dispatch({type: 'SET_FLAGS', flags})
  }, [flags])

  useEffect(() => {
    if (sections && sections.length === 0) {
      dispatch({ type: 'INIT_ASSESSMENT' });
    }
  }, [])

  if (isIE || isOpera) {
    return (<Redirect to='/unsupportedbrowser'/>)
  }
  
  if(global && global.goRegister) {
    return (<Redirect to='/registration'/> );
  }
  
  return (
    <StyledRoot>
      <HomeComponent />
    </StyledRoot>
  );
}

export default WrapperComponent;
